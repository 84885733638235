import React from 'react';

class Archives extends React.Component{
    render(){
        return (
            <div>
                Archives
            </div>
        )
    }
}

export default Archives;