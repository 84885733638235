import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from "react-bootstrap/Image";
// import roundedCircle from "react-bootstrap/roundedCircle";

import Ecardmodules from "./EditorialCardmodules";
// import Ebpic from "./jor.jpeg";
import Rohan from "./Rohan.jpg";
import Sc from "./Saurabh Chaturvedi.png";

import Mo from "./Megha Ojha.jpg";
import Aj from "./Anirudha Joshi.jpg";
import Ds from "./Dweep Shah.jpg";

import Gururaj from './Gururaj Devarhubli.jpeg';
import Arushi from './ARUSHI M. MEHTA.jpg';
import Dweep from "./Dweep Shah.jpg";
import Kn from "./Keshab Nandy.jpg";

import Card from "react-bootstrap/Card";


import '../Styles/editorialboard.css';







const Editorialcarddetails = () => (

  <Container>
    {/* <div>
      <Card className="Ebcard">


        <Card.Body>

          <Card.Title className="cardtitles">MR. VINAY JOY </Card.Title>
          <br></br>
          <Card.Title className="cardtitless">
             EDITOR IN CHIEF

          </Card.Title>


          <Card.Title className="cardtitles">  MR. ROHAN GARJE (B.B.A., LL. B (Hons.), Diploma in Corporate Law)

          </Card.Title>
          <Card.Title className="cardtitles">  FOUNDER – EDITOR IN CHIEF 

          </Card.Title>

          <Card.Title className="cardtitles">
            PUBLISHER
          </Card.Title>
          <Card.Img className="ebpic" src={Rohan} roundedCirle />
         
          <Card.Title className="Abouthim"><u> About him </u></Card.Title>
          <Card.Text className="card_descp">
            Mr. Rohan Garje is a law aficionado. He did his schooling at Fr. Agnel Polytechnic University, Navi Mumbai.
            He is very fascinated by the concept of legal research work and he has secured a number of publications on various platforms including law journals, blogs and newsletters.
            His work covers various issues involved in the field of Criminal Law, Constitutional Law, IPR Law, Taxation Law and Company Law.
            He has also participated in various Moot Court Competitions, Mediation Competitions and Negotiation Competitions at the National Level.
            His legal article on “Arbitral Awards under Section 34: A Brief Overview” was published in NMIMS School of Law Newsletter.
            He is a well-rounded individual who lives with passion, commitment and grace.
          </Card.Text>

          <br></br>
          <Card.Title className="cardtitles"> <u>Contact</u>  </Card.Title>
          <Card.Text className="card_info">
            <b>Contact No.</b>  +91 9372988202
            <br></br>

            <b>Email ID:</b> rohan.garje77@gmail.com
            <br></br>
            <b>Address</b> – C-602, Bhumiraj Manor, Sector – 14, Sanpada, Navi Mumbai, 400 705, Maharashtra
          </Card.Text> 

        </Card.Body>
      </Card>
    </div> */}


    <div>
      <Card className="Ebcard">

        <Card.Body>
          <Card.Title className="cardtitles">MR. ROHAN GARJE (B.B.A., LL. B (Hons.), Diploma in Corporate Law)

          </Card.Title>
          <Card.Title className="cardtitles">FOUNDER – EDITOR IN CHIEF 
          </Card.Title>

          <Card.Title className="cardtitles"> <b>PUBLISHER</b>
          </Card.Title>
          <Card.Img className="ebpic" src={Rohan} />
          <Card.Title className="Abouthim"> <u>About Him</u> </Card.Title>
          <Card.Text className="card_descp">
          Mr. Rohan Garje is a law aficionado. He did his schooling at Fr. Agnel Polytechnic University, Navi Mumbai. He is very fascinated by the concept of legal research work and he has secured a number of publications on various platforms including law journals, blogs and newsletters. His work covers various issues involved in the field of Criminal Law, Constitutional Law, IPR Law, Taxation Law and Company Law. He has also participated in various Moot Court Competitions, Mediation Competitions and Negotiation Competitions at the National Level. His legal article on “Arbitral Awards under Section 34: A Brief Overview” was published in NMIMS School of Law Newsletter. He is a well-rounded individual who lives with passion, commitment and grace. 
          </Card.Text>
          <br></br>
          <Card.Title className="cardtitles"> <u>Contact</u> </Card.Title>
          <Card.Text className="card_info">
            {/* <b>Faculty Profile</b> -

            <a rel="link1" className="link1"
              href="  https://www.nmimsnavimumbai.org/faculty/saurabh-chaturvedi">  https://www.nmimsnavimumbai.org/faculty/saurabh-chaturvedi</a>.
            <br></br> */}
            <b>Contact No.:</b> +91 9372988202
            <br></br>
            <b>Email ID:</b> rohan.garje77@gmail.com
            <br></br>
            <b>Address</b> – C-602, Bhumiraj Manor, Sector – 14, Sanpada, Navi Mumbai, 400 705, Maharashtra
          </Card.Text> 

        </Card.Body>
      </Card>
    </div>







    <div>
      <Card className="Ebcard">

        <Card.Body>
          <Card.Title className="cardtitles">MR. GURURAJ DEVARHUBLI (PH.D. (LAW) LL.M, LL. B)

          </Card.Title>

          <Card.Title className="cardtitles">EDITORIAL BOARD MEMBER
          </Card.Title>

          <Card.Title className="cardtitles">ASSISTANT PROFESSOR OF LAW
          </Card.Title>
          <Card.Img className="ebpic" src={Gururaj} />
          <Card.Title className="Abouthim"><u> About Him </u></Card.Title>
          <Card.Text className="card_descp">
          Mr. Gururaj Devarhubli is an Assistant Professor of Law at Institute of Law, Nirma University. Prior to joining Institute of Law, Nirma University he has worked at Anand College of Legal Studies and Institute of Law, Parul University. He has completed his Masters from Gujarat National Law University, Gandhinagar in Intellectual Property Laws specialization and Bachelors in Law from Maharaja Sayajirao University of Baroda, Vadodara. Currently he is pursuing Ph.D. from Gujarat National Law University, Gandhinagar.
          </Card.Text>
          <br></br>
          <Card.Title className="cardtitles"> <u>Contact </u></Card.Title>
          <Card.Text className="card_info">
            <b>Faculty Profile</b>  -


            <a rel="link1" className="link1"
              href=" https://law.nirmauni.ac.in/author/gururajd/ ">  https://law.nirmauni.ac.in/author/gururajd/ </a>
            <br></br>
            <b>Institutional Email ID:</b> gururaj.devarhubli@nirmauni.ac.in (Professional)
            <br></br>
            <b>Address</b> - Nirma University, Institute of Law, Room No. 204-B, Faculty Cabin, First Floor, Sarkhej - Gandhinagar Hwy, Gota, Ahmedabad, Gujarat 382481
          </Card.Text>

        </Card.Body>
      </Card>
    </div>

    <div>
      <Card className="Ebcard">

        <Card.Body>
          <Card.Title className="cardtitles">MS. ARUSHI M. MEHTA (PH. D (Pursuing) (LAW) LL.M, LL. B)

          </Card.Title>

          <Card.Title className="cardtitles">EDITORIAL BOARD MEMBER
          </Card.Title>

          <Card.Title className="cardtitles">ASSISTANT PROFESSOR OF LAW
          </Card.Title>
          <Card.Img className="ebpic" src={Arushi} />
          <Card.Title className="Abouthim"><u> About Her </u></Card.Title>
          <Card.Text className="card_descp">
          Ms. Arushi completed her B.A. LL.B. (H) from Vivekananda Institute of Professional Studies (affiliated to I.P University) in 2012. She then did litigation in District Courts and High Court of Delhi for a year. She is an alumni of one of the best national law universities in India i.e. West Bengal National University of Juridical Sciences (NUJS) from completed her LLM in Corporate and Commercial Laws in the year 2013-2014. She joined Vivekananda Institute of Professional Studies as a Visiting Faculty. She qualified UGC NET in July 2016. She has also worked as a Law Researcher with Delhi Judicial Academy. Then, she worked in Delhi Metropolitan Education (affiliated to I.P University) as an Assistant Professor in the year 2017. She was the Member of Moot Court Committee as well as organized a National Seminar on Human Rights as a Member Secretary.
          </Card.Text>
          <br></br>
          <Card.Title className="cardtitles"> <u>Contact </u></Card.Title>
          <Card.Text className="card_info">
            <b>Faculty Profile</b>  -
            <a rel="link1" className="link1"
              href=" https://sushantuniversity.edu.in/school-of-law/about-school/faculty-profile/ms-arushi-m-mehta ">  https://sushantuniversity.edu.in/school-of-law/about-school/faculty-profile/ms-arushi-m-mehta </a>
            <br></br>
            <b>Institutional Email ID:</b> arushimenta@ansaluniversity.edu.in (Professional)
            <br></br>
            <b>Address</b> - Sushant University, School of Law, Gold Road, Huda, Sushant Lok 2, Sector 55, Gurugram, Haryana 122003
          </Card.Text>

        </Card.Body>
      </Card>
    </div>
    
    {/* <div>
      <Card className="Ebcard">

        <Card.Body>
          <Card.Title className="cardtitles">ADVOCATE DWEEP SHAH (LL. B, B.B.A)

          </Card.Title>

          <Card.Title className="cardtitles">EDITORIAL BOARD MEMBER
          </Card.Title>

          <Card.Title className="cardtitles">ADVOCATE, BOMBAY HIGH COURT AND LEGAL ASSOCIATE AT JAYAKAR & PARTNERS
          </Card.Title>
          <Card.Img className="ebpic" src={Dweep} />
          <Card.Title className="Abouthim"><u> About Him </u></Card.Title>
          <Card.Text className="card_descp">
          Mr. Dweep Shah is an Advocate in Bombay High Court. He is also serving as a Legal Associate at a law firm called Jayakar & Partners, Bombay. He completed his B.B.A LL. B (Hons.) from Pravin Gandhi School of Law, Bombay. He started practicing as an advocate in Bombay, mainly dealing with Civil Litigation, Arbitration and Dispute Resolution, Real Estate Cases and Land Matters. Advocate Dweep Shah has given talks on Constitutional Law to Law Students. His scholarly activities include attending Workshops, Seminars, Moot Courts, Conferences at National Level and publishing research papers on Constitutional Law. He has interned with various reputed Law Firms. He was also awarded a “Certificate of Merit” from Pravin Gandhi School of Law for his academic performance and research work.
          </Card.Text>
          <br></br>
          <Card.Title className="cardtitles"> <u>Contact </u></Card.Title>
          <Card.Text className="card_info">
            <b>LinkedIn Profile</b>  -
            <a rel="link1" className="link1"
              href=" https://in.linkedin.com/in/dweep-shah-24608a119 ">  https://in.linkedin.com/in/dweep-shah-24608a119 </a>
            <br></br>
            <b>Institutional Email ID:</b> dshah@jayakarpartners.com (Professional)
            <br></br>
            <b>Email ID:</b> shahdweep6@gmail.com (Personal)
            <br></br>
            <b>Address 1 </b> - 1301, Raheja Centre, Free Press Journal Marg, Nariman Point, Mumbai, Maharashtra – 400021
            <br></br>
            <b>Address 2 </b> - Bombay High Court, Fort, Mumbai, Maharashtra 400001
          </Card.Text>

        </Card.Body>
      </Card>
    </div> */}

    <div>
      <Card className="Ebcard">

        <Card.Body>
          <Card.Title className="cardtitles">DR. KESHAB NANDY (PH.D. (MANAGEMENT), LL. B)

          </Card.Title>

          <Card.Title className="cardtitles">EDITORIAL BOARD MEMBER
          </Card.Title>

          <Card.Title className="cardtitles">PROFESSOR OF LAW
          </Card.Title>
          <Card.Img className="ebpic" src={Kn} />
          <Card.Title className="Abouthim"><u> About Him </u></Card.Title>
          <Card.Text className="card_descp">
          Prof. (Dr) Keshab Nandy is Professor of Law and Management at Kirit P. Mehta School of Law, NMIMS Navi Mumbai and comes with rich 39 years of experience spread across Teaching, Civil Service, Magistrate, senior positions in Reserve Bank of India, Unit Trust of India and as SVP, Occupier, CVO and Director in Boards of 5 well known Manufacturing Companies. He is member of various Corporate Committees and Industry bodies like Bombay Chamber of Commerce & Industry etc and recipient of several prestigious awards by different Govt and Industry bodies. He is a reputed motivational speaker in India and abroad and is an acclaimed NLP trainer. His book "Be a Habitual Winner" is a best-seller
          </Card.Text>
          <br></br>
          <Card.Title className="cardtitles"> <u>Contact </u></Card.Title>
          <Card.Text className="card_info">
            <b>Faculty Profile</b>  -
            <a rel="link1" className="link1"
              href=" https://www.nmimsnavimumbai.org/faculty/keshab-nandy/ ">  https://www.nmimsnavimumbai.org/faculty/keshab-nandy/ </a>
            <br></br>
            <b>Institutional Email ID:</b> : keshab.nandy@nmims.edu.in (Professional)
            <br></br>
            <b>Address </b> - Plot No. 2, Pethapada, Next to Ramsheth Thakur College, Sector 33, Kharghar, Navi Mumbai, Maharashtra 410210
          </Card.Text>

        </Card.Body>
      </Card>
    </div>

    <div>
      <Card className="Ebcard">

        <Card.Body>
          <Card.Title className="cardtitles">ADVOCATE ANIRUDHA JOSHI (LL.M, LL. B, B.B.A)

          </Card.Title>

          <Card.Title className="cardtitles">EDITORIAL BOARD MEMBER
          </Card.Title>

          <Card.Title className="cardtitles">FOUNDER AND MANAGING PARTNER OF JURIS ARMOR ADVOCATES & LEGAL ADVISORS, ADVOCATE, BOMBAY HIGH COURT
          </Card.Title>
          <Card.Img className="ebpic" src={Aj} />
          <Card.Title className="Abouthim"><u> About Him </u></Card.Title>
          <Card.Text className="card_descp">
          Advocate Anirudha Joshi is the Founder and Managing Partner of Juris Armor, Advocates and Legal Advisors established 2016, JURIS ARMOR is a full-service, multi-disciplinary, litigation centric law firm with a focus on civil and corporate litigation located in Pune. He is an experienced lawyer practicing for 7 years in Pune District and Sessions Court and the Bombay High Court. He has a good understanding of Arbitration matters, Software & Industrial contracts, NCLT matters, Corporate Advisory, Divorce & Maintenance cases and Property disputes. He has argued over 200 divorce cases over a period of 7 years. He possesses excellent written and verbal communication skills. He is able to present complex material in a clear, concise, and persuasive manner, for a range of audiences. He is also a visiting faculty at Kirit P. Mehta School of Law, NMIMS Navi Mumbai. There he is teaching Law to 5-year B.B.A. LL. B and B.A. LL. B Course. He holds a Masters of Law (LL.M), Bachelor of Law (LL. B) and Bachelors in Business Administration (B.B.A).
          </Card.Text>
          <br></br>
          <Card.Title className="cardtitles"> <u>Contact </u></Card.Title>
          <Card.Text className="card_info">
            <b>Firm Profile</b>  -
            <a rel="link1" className="link1"
              href=" https://jurisarmor.in/our-team/ ">  https://jurisarmor.in/our-team/ </a>
            <br></br>
            <b>Visiting Faculty Profile</b>  -
            <a rel="link1" className="link1"
              href=" https://www.nmimsnavimumbai.org/visiting-faculties/ ">  https://www.nmimsnavimumbai.org/visiting-faculties/ </a>
            <br></br>
            <b>Institutional Email ID:</b> : jurisarmor@gmail.com (Professional)
            <br></br>
            <b>Address </b> - JURIS ARMOR, Advocates & Legal Advisors, Office No 4, Business Guild, Law College Road, Opp. Wadeshwar Restaurant, Erandwane, Pune – 411004
          </Card.Text>

        </Card.Body>
      </Card>
    </div>

     {/* <div>
      <Card className="Ebcard">

        <Card.Body>

        <Card.Title className="cardtitles">MR. KARAN RATHOD  </Card.Title>
          <br></br>
          <Card.Title className="cardtitless">
             EDITOR IN CHIEF

          </Card.Title> */}

          {/* <Card.Title className="cardtitles">ADVOCATE ANIRUDHA JOSHI (LL.M, LL. B, B.B.A)
            EDITORIAL BOARD MEMBER
          </Card.Title>
          <Card.Text>FOUNDER AND MANAGING PARTNER OF JURIS ARMOR ADVOCATES & LEGAL ADVISORS, ADVOCATE, BOMBAY HIGH COURT</Card.Text>
          <Card.Img className="ebpic" src={Aj} />
          <Card.Title className="Abouthim"> <u>About him</u> </Card.Title>
          <Card.Text className="card_descp">
            Advocate Anirudha Joshi is the Founder and Managing Partner of Juris Armor, Advocates and Legal Advisors established 2016,
            JURIS ARMOR is a full-service, multi-disciplinary, litigation centric law firm with a focus on civil and corporate
            litigation located in Pune. He is an experienced lawyer practicing for 7 years in Pune District and Sessions Court and the
            Bombay High Court. He has a good understanding of Arbitration matters, Software & Industrial contracts, NCLT matters,
            Corporate Advisory, Divorce & Maintenance cases and Property disputes. He has argued over 200 divorce cases over a period of
            7 years. He possesses excellent written and verbal communication skills. He is able to present complex material in a clear,
            concise, and persuasive manner, for a range of audiences. He is also a visiting faculty at Kirit P. Mehta School of Law, NMIMS Navi Mumbai. There he is teaching Law to 5-year B.B.A. LL. B and B.A. LL. B Course. He holds a Masters of Law (LL.M), Bachelor of Law (LL. B) and Bachelors in Business Administration (B.B.A).
          </Card.Text>
          <br></br>
          <Card.Title className="cardtitles"> <u>Contact</u> </Card.Title>
          <Card.Text className="card_info">
            <b>Firm Profile</b>  - 
            <a rel="link1" className="link1"
              href=" https://jurisarmor.in/our-team/"> https://jurisarmor.in/our-team/</a>.
            <br></br>
            <b> Faculty Profile </b> -
            <a rel="link1" className="link1"
              href="  https://www.nmimsnavimumbai.org/visiting-faculties/">  https://www.nmimsnavimumbai.org/visiting-faculties/</a>.
            <br></br>
            <b>Institutional Email ID:</b> jurisarmor@gmail.com (Professional)
            <br></br>
            <b>Address</b> - JURIS ARMOR, Advocates & Legal Advisors, Office No 4, Business Guild, Law College Road, Opp. Wadeshwar Restaurant, Erandwane, Pune – 411004

          </Card.Text> */}

        {/* </Card.Body>
      </Card>
    </div> 
 */}




    



  </Container>
);

export default Editorialcarddetails;





//   <div className="text-field-design">
//     <Container>
//     <Row>
//     <Col>
//     <Ecardmodules cardtitle = {cardtitle1} cardText = {carddescp1} cardimg = {cardpic1} />
//     </Col>
//     <Col>
//     <Ecardmodules cardtitle = {cardtitle2} cardText = {carddescp2} />
//     </Col>
//     <Col>
//     <Ecardmodules cardtitle = {cardtitle3} cardText = {carddescp3} />
//     </Col>
//   </Row>

//     <br></br>
//     <br></br>
// <Row>
//     <Col>
//     <Ecardmodules cardtitle = {cardtitle4} cardText = {carddescp4} />
//     </Col>
//     <Col>
//     <Ecardmodules cardtitle = {cardtitle5} cardText = {carddescp5} />
//     </Col>
//     <Col>
//     <Ecardmodules cardtitle = {cardtitle6} cardText = {carddescp6} />
//     </Col>
//     </Row>

//     <br></br>
//     <br></br>

//     <Row>
//     <Col>
//     <Ecardmodules cardtitle = {cardtitle7} cardText = {carddescp7} />
//     </Col>
//     <Col>
//     <Ecardmodules cardtitle = {cardtitle8} cardText = {carddescp8} />
//     </Col>
//     <Col>
//     <Ecardmodules cardtitle = {cardtitle9} cardText = {carddescp9} />
//     </Col>
//     </Row>
//   </Container>
//   </div>