import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import  '../Styles/home.css';
const Homecc = () => (

    <div className="card-body">
        <Card  >
            <Card.Header className="card-header" ><b>Publication Frequency</b></Card.Header>
            <Card.Body>

                <Card.Text className="card-text">
                The journal publishes one issue quarterly i.e. one issue every three months.
                         </Card.Text>
               




            </Card.Body>
        </Card>

    </div>
)

export default Homecc;