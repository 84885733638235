import React from 'react';
import Card from 'react-bootstrap/Card';
import { Row, Col, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import '../Styles/journalPage.css';
import { Navigationbar } from "../partials/Navbar.component";
import Footers from "../partials/Footer";

export class journal extends React.Component {
    constructor() {
        super();
        this.state = {
            forms: [
                {
                    title: 'Driving',
                },
                {
                    title: 'Banking',
                },
                {
                    title: 'HolidayHomes',
                },
                {
                    title: 'VRS',
                },
                {
                    title: 'Insurance',
                },
                {
                    title: 'Income Tax',
                },
                {
                    title: 'Passport',
                },
                {
                    title: 'Govt. Forms',
                },
                {
                    title: 'Railway',
                },
                {
                    title: 'Company',
                },
                {
                    title: 'Pension',
                },
                {
                    title: 'Election',
                }

            ]
        }
    }
    render() {
        return (
            <div>

                <Navigationbar />
                <Container>

                    {/* <center><h3 className="mt-5">Journal papers on the Laws</h3></center> */}
 <br />

                    <hr />

                    <br />


                  
                    <center><h3 className="mt-5">Volume 1 – Issue 1 (February, 2022 to April, 2022)</h3></center>
                    <br></br>
                    <br></br>
                    <div className="forms-options">
                        <Row>
                           
                            <Col>
                                <Link to='/journal/Case' >
                                    <Card className="C_D card card-1" style={{ width: '35rem' }}>

                                        <Card.Body>
                                            <Card.Title className="journytitles">1.  A Case for Adoption Rights for LGBTQ Community
                                            </Card.Title>

                                            <Card.Title>
                                                Author - Sanjay Gadkari
                                            </Card.Title>
                                            <Card.Text>

                                            </Card.Text>

                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>



                            <Col>
                                <Link to='/journal/Analytical' >
                                    <Card className="C_D card card-1" style={{ width: '35rem' }}>

                                        <Card.Body>
                                            <Card.Title className="journytitles">2. An Analytical Study of Interface between Patent Pooling and Competition Laws in India 
                                            </Card.Title>

                                            <Card.Title>
                                                Author - Pallavi Kashyap
                                            </Card.Title>
                                            <Card.Text>

                                            </Card.Text>

                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>




                            <Col>
                                <Link to='/journal/Criminal' >
                                    <Card className="C_D card card-1" style={{ width: '35rem' }}>

                                        <Card.Body>
                                            <Card.Title className="journytitles">3.Criminalising Ecocide Peacetime Ecocide 
                                            </Card.Title>

                                            <Card.Title>
                                                Author - Garv Sharma
                                            </Card.Title>
                                            <Card.Text>

                                            </Card.Text>

                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>


                            <Col>
                                <Link to='/journal/Law' >
                                    <Card className="C_D card card-1" style={{ width: '35rem' }}>

                                        <Card.Body>
                                            <Card.Title className="journytitles">4.MAINTENANCE IN CRIMINAL LAW WITH SPECIFIC REFERNCE TO LIVE -IN -RELAIONSHIP 
                                            </Card.Title>

                                            <Card.Title>
                                                Author - Aditi Singh
                                            </Card.Title>
                                            <Card.Text>

                                            </Card.Text>

                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>





                            

                            <Col>
                                <Link to='/journal/Key' >
                                    <Card className="C_D card card-1" style={{ width: '35rem' }}>

                                        <Card.Body>
                                            <Card.Title className="journytitles">5.The Key Principal of Non-Refoulment 
                                            </Card.Title>

                                            <Card.Title>
                                                Author - Dishani Shetty
                                            </Card.Title>
                                            <Card.Text>

                                            </Card.Text>

                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                            

                            <Col>
                                <Link to='/journal/IJARAH' >
                                    <Card className="C_D card card-1" style={{ width: '35rem' }}>

                                        <Card.Body>
                                            <Card.Title className="journytitles">6.Legal Issues of IJARAH Contract and Rent-To-Own Scheme – An Analysis 
                                            </Card.Title>

                                            <Card.Title>
                                                Author - Gururaj D. Devarhubli & Bushra Sarfaraj Patel
                                            </Card.Title>
                                            <Card.Text>
                                            DOI: www.doi.org/10.5281/zenodo.6608426
                                            </Card.Text> 

                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>

                            <Col>
                                <Link to='/journal/Auditing' >
                                    <Card className="C_D card card-1" style={{ width: '35rem' }}>

                                        <Card.Body>
                                            <Card.Title className="journytitles">7.The Implications of The Covid-19 Pandemic for The Auditing and Assurance Processes
                                            </Card.Title>

                                            <Card.Title >
                                                Author - Bushra Sarfaraj Patel
                                            </Card.Title>
                                            <Card.Text >
                                            DOI: www.doi.org/10.5281/zenodo.6608444
                                            </Card.Text> 

                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                           
                        </Row>

                       

                    </div>

                    <br />

                    <hr />

                    <br />

                  

                </Container>
                <Footers />
            </div>
        )
    }
}




